import React, { ChangeEvent, Fragment } from 'react';
import { GroupItem, CloseButton, ButtonValidar } from '../../styles';
import { Button, Col, Row } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import {
  Categoria,
  ContaBanco,
  Finalizadora,
  InputDate,
  InputMoney,
  InputText,
  InputSelect,
  InputText2,
} from '~/components/NovosInputs';
import { SelectProps } from '@material-ui/core';
import Separator from '~/components/Separator';
import { useAceiteDePagamento } from '../../hooks/useAceiteDePagamento';
import { SelectType } from '~/components/NovosInputs/InputSelect/protocols';
import { Modal } from 'react-bootstrap';

const ModalCaptura: React.FC = () => {
  const {
    openModal,
    handleCloseModal,
    validacaoBoleto,
    isValid,
    lojas,
    apply,
    registerModal,
    setValueModal,
    controlModal,
    clearErrorsModal,
    errorsModal,
  } = useAceiteDePagamento();

  return (
    <>
      <Modal
        show={openModal}
        onHide={handleCloseModal}
        className="modal-fade absolute inset-0 overflow-auto bg-gray-50"
        centered
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Captura Aceite</Modal.Title>
          <CloseButton type="button" onClick={handleCloseModal}>
            <AiOutlineClose size={24} />
          </CloseButton>
        </Modal.Header>
        <Modal.Body>
          <>
            <Row>
              <Col md={10} sm={12}>
                <InputText2
                  name="captura"
                  label="Captura"
                  register={registerModal}
                  control={controlModal}
                  isError={false}
                  maxLength={51}
                  onChange={(e: any) => {
                    const { value } = e.target;
                    const valueReplace = value.replace(/[^0-9]/g, '');
                    clearErrorsModal('captura');
                    setValueModal('captura', valueReplace);
                  }}
                />
              </Col>
              <Col md={2} sm={12}>
                <ButtonValidar
                  variant="success"
                  onClick={() => validacaoBoleto()}
                >
                  VALIDAR
                </ButtonValidar>
              </Col>
            </Row>

            <GroupItem>
              <span>Dados do Aceite:</span>
              <Separator />
            </GroupItem>
            <Row>
              <Col md={3} sm={12}>
                <InputSelect
                  register={registerModal}
                  control={controlModal}
                  options={[
                    { label: 'Simplificado', value: 0 },
                    { label: 'Boleto', value: 1 },
                    { label: 'Concessionária', value: 2 },
                  ]}
                  label="Tipo do Aceite"
                  placeholder="Informe o Tipo do Aceite"
                  name="tipo_aceite"
                  isError={errorsModal.tipo_aceite}
                  changeSelected={(selected: SelectType) => {
                    setValueModal('tipo_aceite', selected);
                  }}
                />
              </Col>
              <Col md={3} sm={12}>
                <InputMoney
                  label="Valor"
                  register={registerModal}
                  placeholder="0,00"
                  control={controlModal}
                  isError={errorsModal.val_captura}
                  name="val_captura"
                  min={0.01}
                />
              </Col>
              <Col md={3} sm={12}>
                <InputDate
                  control={controlModal}
                  register={registerModal}
                  name="dta_vencimento"
                  label="Vencimento"
                  isError={errorsModal.dta_vencimento}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    clearErrorsModal('dta_vencimento');
                    setValueModal('dta_vencimento', e.target.value);
                  }}
                />
              </Col>
              <Col md={3} sm={12}>
                <InputText
                  label="N.º Fat/Dup"
                  register={registerModal}
                  control={controlModal}
                  name="num_fat_dup"
                  placeholder="Informe o N.º Fat/Dup"
                  maxLength={10}
                  caseInput="upper"
                  isError={!!errorsModal.num_fat_dup}
                  onInput={(ev: any) => {
                    const value = ev.target.value as string;
                    const regexCaracteresEspeciais =
                      /[!@#$^&*()_+{}[\]:;<>,.?~'-]/;
                    if (
                      regexCaracteresEspeciais.test(value) ||
                      value.toString().includes('=')
                    ) {
                      return setValueModal('num_fat_dup', '');
                    }
                    setValueModal('num_fat_dup', value);
                  }}
                />
              </Col>
            </Row>
            <GroupItem>
              <span>Novos Dados do Aceite</span>
              <Separator />
            </GroupItem>
            <Row>
              <Col md={3} sm={12}>
                <InputDate
                  control={controlModal}
                  register={registerModal}
                  name="dta_emissao"
                  label="Emissão"
                  isError={errorsModal.dta_emissao}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    clearErrorsModal('dta_emissao');
                    setValueModal('dta_emissao', e.target.value);
                  }}
                />
              </Col>
              <Col md={9} sm={12}>
                <ContaBanco
                  name="conta"
                  label="Conta"
                  placeholder="Selecione..."
                  register={registerModal}
                  loja={lojas}
                  isError={errorsModal.conta}
                  control={controlModal}
                  changeSelected={(selected: SelectType) => {
                    clearErrorsModal('conta');

                    setValueModal('conta', selected);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={5} sm={12}>
                <Finalizadora
                  name="finalizadora"
                  label="Finalizadora"
                  placeholder="Selecione..."
                  loja={lojas}
                  register={registerModal}
                  control={controlModal}
                  isError={errorsModal.finalizadora}
                  style={{ width: '100%' }}
                  changeSelected={(selected: SelectProps) => {
                    clearErrorsModal('finalizadora');

                    setValueModal('finalizadora', selected);
                  }}
                />
              </Col>
              <Col md={7} sm={12}>
                <Categoria
                  label="Categoria"
                  tipoCategoria="Despesa"
                  placeholder="Selecione..."
                  register={registerModal}
                  isError={!!errorsModal.despesa}
                  control={controlModal}
                  name="despesa"
                  changeSelected={(selected: any) => {
                    clearErrorsModal('despesa');
                    setValueModal('despesa', selected);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: '10px',
                  marginTop: '20px',
                }}
              >
                <Button variant="success" onClick={() => apply()}>
                  APLICAR
                </Button>
              </div>
            </Row>
          </>
          <br />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalCaptura;
