import * as yup from 'yup';

export const schemaModal = yup
  .object({
    tipo_aceite: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    val_captura: yup.string().required(),
    dta_vencimento: yup.string().required().nullable(),
    num_fat_dup: yup.string().required(),
    dta_emissao: yup.string().required().nullable(),
    conta: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    finalizadora: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
    despesa: yup.object().shape({
      label: yup.string().required(),
      value: yup.number().required(),
    }),
  })
  .required();
